import { useMutation } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';
export const useReviewRequestResolutionMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const createBlacklist = async (variables) => {
        const res = await getHttpClient({ headers }).post(`/api/admin/review-requests/${variables._id}/resolution`, variables.body);
        return res.data;
    };
    return useMutation(createBlacklist, {
        onError: handleHttpClientError,
        ...opts,
    });
};
