import { Text } from '@mantine/core';
import { shortenAddress } from '@creatorhub/shared/util';

type MeTextAddressProps = {
  address: string;
  // inherint mantine parent Text props
  inherit?: boolean;
  // shorten the hash
  shorten?: boolean;
};

export const MeTextAddress = (props: MeTextAddressProps) => {
  return (
    <Text inherit={props.inherit} style={{ fontFamily: 'monospace' }}>
      {props.shorten ? shortenAddress(props.address) : props.address}
    </Text>
  );
};
