import {
  CollectionCategories,
  IMeCollection,
} from '@creatorhub/shared/creators';
import {
  CollectionCreatePayload,
  CollectionCreateSchema,
} from '@creatorhub/shared/admin';
import {
  Button,
  Drawer,
  Textarea,
  TextInput,
  Image,
  Switch,
  Select,
  JsonInput,
  MultiSelect,
  NumberInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { structForm } from 'src/utils/superstruct-form-validation';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { MeTextHint } from 'src/components/MeTextHint';

type CollectionCreateFormProps = {
  form: UseFormReturnType<Partial<IMeCollection>>;
  onSubmit: (params: { body: CollectionCreatePayload }) => void;
  isLoading?: boolean;
};

/**
 * Form to edit collection draft on the CH side
 * update can be pushed to change the collection document
 * on the marketplace side
 */
const CollectionCreateForm = (props: CollectionCreateFormProps) => {
  return (
    <form
      onSubmit={props.form.onSubmit((formValues: any) => {
        const { candyMachineIds, mint, isDraft, ...restValues } = formValues;
        props.onSubmit({
          body: {
            ...restValues,
            // TODO: fix typedef for useForm JsonInput returns strings not parsed JSON
            candyMachineIds: JSON.parse(candyMachineIds ?? '[]'),
            mint: JSON.parse(mint ?? '[]'),
            isDraft: isDraft ?? false,
          },
        });
      })}
    >
      <TextInput label="Symbol" {...props.form.getInputProps('symbol')} />
      <TextInput label="Name" {...props.form.getInputProps('name')} />
      <Textarea
        label="Description"
        {...props.form.getInputProps('description')}
      />
      <NumberInput
        label="Total Supply"
        {...props.form.getInputProps('totalItems')}
        min={0}
        precision={0}
      />
      <TextInput label="Image URL" {...props.form.getInputProps('image')} />
      <Select
        label="NFT Image Type"
        clearable
        data={['jpeg', 'gif', 'mp4']}
        {...props.form.getInputProps('nftImageType')}
      />
      {props.form.values.image && (
        <Image mb="lg" width={400} src={props.form.values.image} />
      )}
      <MultiSelect
        label="Categories"
        description="max 2, the first category selected will be the primary one"
        clearable
        maxSelectedValues={2}
        data={Object.values(CollectionCategories)}
        {...props.form.getInputProps('categories')}
      />
      <TextInput
        label="Website"
        placeholder="https://"
        {...props.form.getInputProps('website')}
      />
      <TextInput
        label="Twitter"
        placeholder="https://"
        {...props.form.getInputProps('twitter')}
      />
      <TextInput
        label="Discord"
        placeholder="https://"
        {...props.form.getInputProps('discord')}
      />
      <JsonInput
        label="Candy Machine IDs"
        defaultValue="[]"
        formatOnBlur
        {...props.form.getInputProps('candyMachineIds')}
      />
      <JsonInput
        label="Hash List"
        defaultValue="[]"
        {...props.form.getInputProps('mint')}
      />
      <Select
        label="Force Sort By"
        clearable
        data={[
          {
            value: 'priceLowToHigh',
            label: 'Price ASC',
          },
          {
            value: 'priceHighToLow',
            label: 'Price DESC',
          },
          {
            value: 'recentlyListed',
            label: 'Recently listed',
          },
          {
            value: 'None',
            label: 'None',
          },
        ]}
        {...props.form.getInputProps('sortBy')}
      />
      <Switch
        label="Create as Draft"
        {...props.form.getInputProps('isDraft')}
      />
      {/* the following fields are missing in retool but I believe we can add then
          to simplify the collection creation flow
      <TextInput label="Iframe link" {...props.form.getInputProps('iframe')} />
      <Switch
        label="Enable Attributes Filter"
        {...props.form.getInputProps('enabledAttributesFilters')}
      />
      <Switch
        label="Enable Version Filter"
        {...props.form.getInputProps('enabledVersionFilter')}
      />
      <Switch label="Is Verified" {...props.form.getInputProps('isVerified')} />
      <Switch
        label="Enable Moonrank Rank"
        {...props.form.getInputProps('rarity.showMoonrank')}
      />
      <Switch
        label="Enable Howrare.is Rank"
        {...props.form.getInputProps('rarity.showHowrare')}
      />
      <Switch
        label="Enable Magic Eden Rank"
        {...props.form.getInputProps('rarity.showMagicEden')}
      />
      <Switch
        label="Enable Total Supply"
        {...props.form.getInputProps('enabledTotalSupply')}
      />
      <Switch
        label="Enable Unique Owners"
        {...props.form.getInputProps('enabledUniqueOwners')}
      />
      <Switch
        label="Exclude From Popular Collections"
        {...props.form.getInputProps('excludeFromPopularCollections')}
      />
      <Switch
        label="Is Derivative"
        {...props.form.getInputProps('isDerivative')}
      />
      {props.form.values.isDerivative && (
        <>
          <TextInput
            disabled={!props.form.values.isDerivative}
            label="Derivative Original Collection Name"
            {...props.form.getInputProps('derivativeDetails.originName')}
          />
          <TextInput
            disabled={!props.form.values.isDerivative}
            label="Link to Original Collection"
            {...props.form.getInputProps('derivativeDetails.originLink')}
          />
        </>
      )}
      <TextInput
        label="MCC address"
        description="on-chain collection address"
        {...props.form.getInputProps('onChainCollectionAddress')}
      />
      <JsonInput
        label="Blocked Mints"
        {...props.form.getInputProps('blockedMints')}
      />
      <JsonInput
        label="Black List Traits"
        description="will ignore them for rarity calculation"
        {...props.form.getInputProps('blackListAttributes')}
      />*/}
      {/* TODO: add mutation loagin disabled props to the button to prevent multiple submissions */}
      <ErrorMessage errors={props.form.errors} />
      <Button
        type="submit"
        loading={props.isLoading}
        disabled={!props.form.isDirty()}
      >
        Create {props.form.values.symbol} Collection
      </Button>
      <MeTextHint>
        a corresponding collection draft will be created and you will become its
        owner
      </MeTextHint>
    </form>
  );
};

type CollectionCreateEditorProps = {
  onSubmit: (params: { body: CollectionCreatePayload }) => void;
  loading: boolean;
  onClose: () => void;
};

export const CollectionCreateEditor = (props: CollectionCreateEditorProps) => {
  const collectionCreateForm = useForm<any>(
    structForm({ isDraft: false }, CollectionCreateSchema),
  );

  return (
    <Drawer
      opened
      onClose={() => {
        if (!collectionCreateForm.isDirty() || confirm('discard editing?')) {
          props.onClose();
        }
      }}
      title="Onboard New Collection"
      padding="lg"
      size="600px"
      //styles={{ drawer: { overflowY: 'scroll' } }}
    >
      <CollectionCreateForm
        form={collectionCreateForm}
        onSubmit={props.onSubmit}
        isLoading={props.loading}
      />
    </Drawer>
  );
};
