import { useMutation } from 'react-query';
import { useAuth } from 'src/hooks';
import { getHttpClient } from 'src/queries/http-client';
export const useUpdateCollectionDraftMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const updateCollectionDraft = async (variables) => {
        const res = await getHttpClient({ headers }).patch(`/api/admin/collection-drafts/${variables.draftId}`, variables.body);
        return res.data;
    };
    return useMutation(updateCollectionDraft, opts);
};
export const useGradeCollectionDraftMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const updateCollectionDraft = async (variables) => {
        const res = await getHttpClient({ headers }).post(`/api/admin/grade-collection-draft`, variables.body);
        return res.data;
    };
    return useMutation(updateCollectionDraft, opts);
};
export const useUpdateInternalReviewMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const updateDraftInternalReview = async (variables) => {
        const res = await getHttpClient({ headers }).post(`/api/admin/collection-reviews/new`, variables.body);
        return res.data;
    };
    return useMutation(updateDraftInternalReview, opts);
};
