import { validate } from 'superstruct';
const DEFAULT_OPTS = {
    coerce: true,
};
/**
 * handles cases when there are no validation errors and there are
 * validation errors and returns an object where keys are form fields
 * and values are validation error messages that mantine/forms
 * is expecting
 */
const structErrorsToFormErrors = (err) => {
    const formErrs = {};
    if (!err) {
        return formErrs;
    }
    for (const fieldError of err.failures()) {
        const fieldName = fieldError.path.join(' ');
        formErrs[fieldError.path.join('.')] = fieldName + ': ' + fieldError.message;
    }
    return formErrs;
};
/**
 * NOTE: mantine/form only works with synchronous validate functions
 *
 * @returns function for valiate property of mantine/form useForm
 *
 * @example
 *    useForm({
 *      validate: structFormValidation(structSchema)
 *    })
 */
export function structFormValidation(schema, opts = {}) {
    return (values) => {
        const [err, _values] = validate(values, schema, {
            ...DEFAULT_OPTS,
            ...opts,
        });
        return structErrorsToFormErrors(err);
    };
}
export const structForm = (props, struct, opts = {}) => {
    const initialValues = {};
    // remove fields that do not in the schema from form values
    // TODO: remove nested fields that are no in the schema
    for (const schemaProp in struct.schema) {
        initialValues[schemaProp] = props[schemaProp];
    }
    return {
        initialValues,
        validate: structFormValidation(struct, opts),
    };
};
