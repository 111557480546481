import { Container, Title } from '@mantine/core';
import { Link, useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const routerErr = useRouteError();
  let errMessage = 'Oops, an unexpected error has occured';
  let errStack = '';
  if (routerErr instanceof Error) {
    errMessage = routerErr.message;
    errStack = routerErr.stack || '';
  }

  return (
    <Container>
      <Title>{errMessage}</Title>
      <pre>{errStack}</pre>
      <Link to="/">Go to main page</Link>
    </Container>
  );
};
