import { IReviewRequest } from '@creatorhub/shared/admin';
import { AdminLayout } from 'src/components/layout/AdminLayout';
import { ReviewPipeline } from 'src/components/review-pipeline/ReviewPipeline';
import { useCreatorHubQuery } from 'src/queries';
import { Text } from '@mantine/core';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { useEffect, useState } from 'react';

const normalizeReviewRequest = (res: {
  data: IReviewRequest[];
}): IReviewRequest[] => {
  return res.data.map((item: any) => ({
    ...item,
    createdAt: new Date(item.createdAt),
  }));
};

export const ReviewRequestsDashboardPage = () => {
  const reviewRequestsQuery = useCreatorHubQuery<
    { data: IReviewRequest[] },
    unknown,
    IReviewRequest[]
  >(['api', 'admin', 'review-requests'], {
    cacheTime: 0,
    select: normalizeReviewRequest,
    refetchInterval: 4500,
  });
  const [lastRefreshed, setLastRefreshed] = useState('...loading');

  useEffect(() => {
    const updateLastRefreshed = () => {
      setLastRefreshed(
        formatDistanceStrict(
          new Date(reviewRequestsQuery.dataUpdatedAt),
          new Date(),
          { addSuffix: true },
        ),
      );
    };
    const intervalId = setInterval(updateLastRefreshed, 1000);
    return () => clearInterval(intervalId);
  });

  return (
    <AdminLayout
      loading={reviewRequestsQuery.isLoading}
      breadcrumbs={[{ title: 'Reviews', path: '/review-requests' }]}
      header={<Text color="dimmed">last refreshed {lastRefreshed}</Text>}
    >
      <ReviewPipeline
        reviewRequests={reviewRequestsQuery.data ?? []}
        refetch={reviewRequestsQuery.refetch}
      />
    </AdminLayout>
  );
};
