import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  LoadingOverlay,
  Menu,
  NavLink,
  Skeleton,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import {
  TbMoon,
  TbHome,
  TbSun,
  TbLogout,
  TbUserCircle,
  TbRobotOff,
  TbUsers,
  TbReportSearch,
} from 'react-icons/tb';
import { useState } from 'react';
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Badge,
} from '@mantine/core';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { useCreatorHubQuery } from 'src/queries';

type BreadcrumbsProps = {
  items: Array<{
    title: string;
    path: string;
  }>;
};

type TotalReviewsProps = {
  totalReviews: number;
};

const AdminBreadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <Breadcrumbs>
      <Text>Admin Hub</Text>
      {props.items.map(item => (
        <Anchor key={item.path} href={item.path}>
          {item.title}
        </Anchor>
      ))}
    </Breadcrumbs>
  );
};

function AdminNavigation(props: TotalReviewsProps) {
  return (
    <>
      <RouterNavLink to="/" end>
        {nav => (
          <NavLink label="Main" icon={<TbHome />} active={nav.isActive} />
        )}
      </RouterNavLink>
      <RouterNavLink to="/review-requests">
        {nav => (
          <NavLink
            label="Review requests"
            icon={<TbReportSearch />}
            active={nav.isActive}
          />
        )}
      </RouterNavLink>
      <RouterNavLink to="/collection-drafts">
        {nav => (
          <NavLink
            label="Listings"
            icon={
              <Badge size="xs" variant="filled" color="red" w={16} h={16} p={0}>
                {props.totalReviews}
              </Badge>
            }
            active={nav.isActive}
          />
        )}
      </RouterNavLink>
      <RouterNavLink to="/blacklist">
        {nav => (
          <NavLink
            label="Blacklist"
            icon={<TbRobotOff />}
            active={nav.isActive}
          />
        )}
      </RouterNavLink>
      <RouterNavLink to="/users">
        {nav => (
          <NavLink label="Users" icon={<TbUsers />} active={nav.isActive} />
        )}
      </RouterNavLink>
    </>
  );
}

type AdminLayoutProps = {
  breadcrumbs?: Array<{
    title: string;
    path: string;
  }>;
  header?: React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
  totalReviews?: number;
};

export function AdminLayout(props: AdminLayoutProps) {
  const theme = useMantineTheme();
  const { toggleColorScheme } = useMantineColorScheme();
  const [opened, setOpened] = useState(false);

  const auth = useAuth();
  const currentUserQuery = useCreatorHubQuery<{ _id: string; email: string }>([
    'api',
    'admin',
    'me',
  ]);

  let breadcrumbsItems = [{ title: 'Main', path: '/' }];
  if (props.breadcrumbs) {
    breadcrumbsItems = breadcrumbsItems.concat(props.breadcrumbs);
  }

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 150, lg: 200 }}
        >
          <AdminNavigation totalReviews={props.totalReviews ?? 0} />
        </Navbar>
      }
      header={
        <Header height={60} p="md">
          <Group sx={{ height: '100%' }} px={12} spacing="xl">
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened(o => !o)}
                size="sm"
                mr="xl"
              />
            </MediaQuery>
            <AdminBreadcrumbs items={breadcrumbsItems} />
            {props.header ? props.header : null}
            <Group style={{ marginLeft: 'auto' }}>
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button variant="default">
                    <TbUserCircle size={18} />
                    <Text ml="1rem">
                      {currentUserQuery.data?.email || (
                        <Skeleton height="1rem" width="5rem" />
                      )}
                    </Text>
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item icon={<TbLogout />} onClick={auth.signout}>
                    Log out
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <ActionIcon
                variant="default"
                onClick={() => toggleColorScheme()}
                size={30}
              >
                {theme.colorScheme === 'dark' ? <TbSun /> : <TbMoon />}
              </ActionIcon>
            </Group>
          </Group>
        </Header>
      }
    >
      <LoadingOverlay
        visible={props.loading ?? false}
        transitionDuration={300}
        style={{ position: 'fixed' }}
      />
      {props.children}
    </AppShell>
  );
}
