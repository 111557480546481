import { FC, ReactNode, useMemo } from 'react';
import { IUserLight } from '@creatorhub/shared/launchpad';
import { MeTable, MeTableCell } from '../MeTable';
import { Button, Menu } from '@mantine/core';
import { TbDots } from 'react-icons/tb';

export interface UsersTableAction {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  color?: string;
}

type TableUser = IUserLight & {
  actions?: UsersTableAction[];
};

interface UsersTableProps {
  data: TableUser[];
}

export const UsersTable: FC<UsersTableProps> = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessor: '_id',
        Header: 'ID',
      },
      {
        accessor: 'email',
        Header: 'Email',
      },
      {
        accessor: 'actions',
        Cell({ row: { _id, actions } }: MeTableCell<TableUser>) {
          if (!actions?.length) {
            return null;
          }
          return (
            <Menu>
              <Menu.Target>
                <Button compact variant="subtle">
                  <TbDots />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                {actions.map(({ title, color, icon, onClick }) => (
                  <Menu.Item
                    key={title}
                    color={color}
                    icon={icon}
                    onClick={onClick}
                  >
                    {title}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          );
        },
      },
    ],
    [],
  );

  return <MeTable highlightOnHover columns={columns} data={data} />;
};
