import { Table, MantineNumberSize } from '@mantine/core';
import _get from 'lodash/get';
import { ReactElement, ReactFragment } from 'react';

export type MeTableCell<D> = {
  row: D;
};

export type MeTableColum<D extends Record<string, any>> = {
  // TODO: find a solution for nested keyof D,
  // e.g. { a: { b: 2 } } with accessor: 'a.b'
  accessor: string;
  Header?: ReactElement | ReactFragment | string | React.FC;
  Cell?: (
    props: MeTableCell<D>,
  ) => ReactElement | ReactFragment | string | null;
  columnProps?: { width?: string };
};

type MeTableProps<D extends Record<string, any>> = {
  primaryKey?: keyof D;
  columns: MeTableColum<D>[];
  data: D[];
  loading?: boolean;
  caption?: string;
  highlightOnHover?: boolean;
  verticalSpacing?: MantineNumberSize;
};

export const MeTable = <D extends Record<string, any>>(
  props: MeTableProps<D>,
) => {
  const headers = (
    <tr>
      {props.columns.map((column, idx) => (
        <th
          key={column.accessor.toString() + '_header' + idx}
          {...column.columnProps}
        >
          {typeof column.Header === 'function' ? (
            <column.Header />
          ) : (
            column.Header
          )}
        </th>
      ))}
    </tr>
  );

  let body: any = null;
  if (props.loading) {
    body = <div>Loading...</div>;
  } else if (props.data) {
    body = props.data.map((row, rowIdx) => {
      return (
        <tr key={row[props.primaryKey ?? '_id'] ?? rowIdx}>
          {props.columns.map((column, columnIdx) => (
            <td key={column.accessor.toString() + '_column' + columnIdx}>
              {column.Cell
                ? column.Cell({ row: row })
                : _get(row, column.accessor)}
            </td>
          ))}
        </tr>
      );
    });
  }

  return (
    <Table
      highlightOnHover={props.highlightOnHover}
      verticalSpacing={props.verticalSpacing}
    >
      {props.caption && <caption>{props.caption}</caption>}
      <thead>{headers}</thead>
      <tbody>{body}</tbody>
    </Table>
  );
};
