import { ICollectionDraftResponse } from '@creatorhub/shared/creators';
import { Code, Text, Timeline } from '@mantine/core';
import { formatDateTs } from './CollectionDraftsTable';

type OperationsViewerProps = {
  ops: ICollectionDraftResponse['operations'];
  limit?: number;
  compact?: boolean;
};

export const OperationsViewer = (props: OperationsViewerProps) => {
  const opsList = props.limit ? props.ops.slice(0, props.limit) : props.ops;

  return (
    <Timeline lineWidth={2} bulletSize={16} mb="lg">
      {opsList.map(op =>
        props.compact ? (
          <Timeline.Item
            mb="sm"
            key={formatDateTs(op.performedAt)}
            title={
              <Code>
                {`[${formatDateTs(op.performedAt)}][${op.user?.email}] ${
                  op.opType
                }`}{' '}
                {op.message && ` - ${op.message}`}
              </Code>
            }
          />
        ) : (
          <Timeline.Item key={formatDateTs(op.performedAt)} title={op.opType}>
            <Text color="dimmed" size="sm">
              {op.user?.email} {op.message}
            </Text>
            <Text size="xs" mt={4}>
              {formatDateTs(op.performedAt)}
            </Text>
          </Timeline.Item>
        ),
      )}
    </Timeline>
  );
};
