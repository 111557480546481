import { useQuery } from 'react-query';
import { useAuth } from 'src/hooks';
import { getHttpClient, getQueryPathFromQueryKey, handleHttpClientError, } from './http-client';
const creatoHubApi = getHttpClient();
/**
 * A query function for react-query useQuery
 * allows to pass only query key to make a HTTP GET query to Creator Hub API
 *
 * @example
 *    // makes GET /api/admin/collection-drafts query
 *    useCreatorHubQuery(['admin', 'collectoin-drafts'])
 * @example
 *    // makes GET /api/admin/collection-drafts?limit=25&offset=50&listed=true
 *    // note: object query string params should always be the last item in queryKey array
 *    useCreatorHubQuery(['admin', 'collectoin-drafts', { limit: 25, offset: 50, listed: true }])
 * @example
 *    // makes GET /api/admin/collection-draft/<collection draft id>/organization query
 *    useCreatorHubQuery(['admin', 'collectoin-drafts', collectionDraft.id, 'organization'])
 *
 * @param params
 * @param params.queryKey - array of react-query useQuery keys,
 *    to add query string pass an object with query string key values as last item
 * @param params.meta - useQuery meta object
 * @param params.meta.authorization - authorization herader, e.g. "Bearer some-auth-token", optional
 */
const creatorHubApiQueryFn = async ({ queryKey, meta = {}, }) => {
    let headers;
    if (meta.authorization) {
        headers = headers ?? {};
        headers.authorization = meta.authorization;
    }
    const [endpointPath, queryStringParams] = getQueryPathFromQueryKey(queryKey);
    const res = await creatoHubApi.get(endpointPath + queryStringParams, {
        headers,
    });
    return res.data;
};
export const useCreatorHubQuery = (queryKey, opts = {}) => {
    const { authorization } = useAuth();
    const defaultMeta = {};
    if (queryKey.slice(0, 2).join('/') === 'api/admin' && authorization) {
        // add Authorization header for all `/api/admin/**` paths
        defaultMeta.authorization = authorization;
    }
    const { meta: metaExtension, ...restOptions } = opts;
    return useQuery(queryKey, creatorHubApiQueryFn, {
        retry: 0,
        refetchOnWindowFocus: false,
        ...restOptions,
        meta: { ...metaExtension, ...defaultMeta },
        onError: handleHttpClientError,
    });
};
