import { useCallback, useState } from 'react';
export const usePagination = (opts) => {
    const initialPage = Math.max(opts.initialPage || 0, 1);
    const pageSize = opts.pageSize;
    const [currentPage, setPage] = useState(initialPage);
    return {
        currentPage,
        setPage,
        offset: (currentPage - 1) * pageSize,
        getTotalPages: useCallback((totalItems) => Math.ceil(totalItems / pageSize), [pageSize]),
        hasPages: useCallback((totalItems) => totalItems > pageSize, [pageSize]),
    };
};
