import { Container, Title } from '@mantine/core';
import { Link } from 'react-router-dom';

export const SignedOutPage = () => {
  return (
    <Container>
      <Title>You have successfuly logged out</Title>
      <Link to="/">Login back</Link>
      <p>or close this page</p>
    </Container>
  );
};
