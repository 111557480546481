import { Title } from '@mantine/core';
import { AdminLayout } from 'src/components/layout/AdminLayout';

export const MainPage = () => {
  return (
    <AdminLayout>
      <Title>Main Page</Title>
    </AdminLayout>
  );
};
