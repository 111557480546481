import { Alert, List } from '@mantine/core';
import { ReactNode } from 'react';

type ErrorMessageProps = {
  title?: string;
  errors: string[] | Record<string, ReactNode>;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
  const errors = Array.isArray(props.errors)
    ? props.errors
    : Object.values(props.errors);
  if (!errors.length) {
    return null;
  }

  return (
    <Alert mb="lg" title={props.title ?? 'Error(s)'} color="red">
      <List>
        {errors.map((msg, idx) => (
          <List.Item key={idx}>{msg}</List.Item>
        ))}
      </List>
    </Alert>
  );
};
