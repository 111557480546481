if (!process.env.REACT_APP_OKTA_CLIENT_ID) {
    throw new Error('REACT_APP_CLIENT_ID env variable is not set');
}
if (!process.env.REACT_APP_OKTA_ISSUER) {
    throw new Error('REACT_APP_CLIENT_ID env variable is not set');
}
export const oktaAuthOptions = Object.freeze({
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK === 'true',
});
