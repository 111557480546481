import { IBlacklist } from '@creatorhub/shared/admin';
import { Button, Group, TextInput } from '@mantine/core';
import { useState } from 'react';
import { TbPlus, TbSearch } from 'react-icons/tb';
import {
  BlacklistCreateEditor,
  BlacklistTable,
  useSelectedRows,
} from 'src/components/blacklist';
import { AdminLayout } from 'src/components/layout/AdminLayout';
import {
  useBulkDeleteBlacklistMutation,
  useCreateBlacklistMutation,
} from 'src/mutations/blacklist';
import { useCreatorHubQuery } from 'src/queries';
import notifications from 'src/utils/notifications';

/**
 * Page to manage blacklist documents
 */
export const BlacklistPage = () => {
  const [searchQueryString, setSearchQueryString] = useState<string>('');
  const [isAddBlacklistOpen, setAddBlacklistOpen] = useState<boolean>(false);
  const rowsSelection = useSelectedRows();

  const blacklistQuery = useCreatorHubQuery<{
    data: IBlacklist[];
  }>(['api', 'admin', 'blacklist', { q: searchQueryString }], {
    enabled: searchQueryString.trim() !== '',
    cacheTime: 0,
    onSuccess: () => {
      rowsSelection.reset();
    },
  });

  const createBlacklistMutation = useCreateBlacklistMutation({
    onSuccess: () => {
      setAddBlacklistOpen(false);
      if (searchQueryString.trim() !== '') {
        blacklistQuery.refetch();
      }
      notifications.success('blacklist item has been added');
    },
  });

  const bulkDeleteMutation = useBulkDeleteBlacklistMutation({
    onSuccess: res => {
      rowsSelection.setSelectedRowsIds(new Set());
      if (searchQueryString.trim() !== '') {
        blacklistQuery.refetch();
      }
      if (res.data.deleted === 0) {
        notifications.info('no blacklist items were deleted');
      } else if (res.data.deleted === 1) {
        notifications.success('one blacklist item was deleted');
      } else {
        notifications.success(
          `${res.data.deleted} blacklist items were deleted`,
        );
      }
    },
  });

  return (
    <AdminLayout
      breadcrumbs={[{ title: 'Blacklist', path: '/blacklist' }]}
      loading={blacklistQuery.isFetching}
    >
      {/* page actions and filters */}
      <Group>
        <TextInput
          autoFocus
          icon={<TbSearch />}
          description="Find blacklist values that starts with"
          onBlur={event => {
            setSearchQueryString(event.currentTarget.value.trim());
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              // set search value when enter key is presed
              setSearchQueryString(event.currentTarget.value.trim());
            }
          }}
        />
        <Button
          disabled={rowsSelection.selectedRowsIds.size === 0}
          variant="light"
          color="red"
          loading={bulkDeleteMutation.isLoading}
          onClick={() => {
            const ids = Array.from(rowsSelection.selectedRowsIds);
            if (ids.length) {
              bulkDeleteMutation.mutate({ body: { ids } });
            }
          }}
        >
          Delete Selected
        </Button>
        <Button
          leftIcon={<TbPlus />}
          disabled={isAddBlacklistOpen}
          onClick={() => setAddBlacklistOpen(true)}
        >
          Add Blacklist Record
        </Button>
      </Group>

      {/* blacklist table */}
      <BlacklistTable
        data={blacklistQuery.data?.data || []}
        {...rowsSelection}
      />

      {/* collection create form */}
      <BlacklistCreateEditor
        opened={isAddBlacklistOpen}
        onSubmit={createBlacklistMutation.mutate}
        onClose={() => setAddBlacklistOpen(false)}
        loading={createBlacklistMutation.isLoading}
      />
    </AdminLayout>
  );
};
