import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  Tuple,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { Loading } from 'src/components/Loading';
import { ErrorPage } from 'src/pages/ErrorPage';
import { ListCollectionDraftsPage } from 'src/pages/ListCollectionDraftsPage';
import { MainPage } from 'src/pages/MainPage';
import { SignedOutPage } from 'src/pages/SignedOutPage';
import { BlacklistPage } from 'src/pages/BlacklistPage';
import { oktaAuthOptions } from 'src/config';
import { ReviewRequestsDashboardPage } from './pages/ReviewRequestsDashboardPage';
import { UsersPage } from './pages/UsersPage';

const queryClient = new QueryClient();

export const oktaAuth = new OktaAuth(oktaAuthOptions);

const magicEdenTheme = {
  globalStyles: () => ({
    'nav  a': {
      textDecoration: 'none',
    },
  }),
  components: {
    TextInput: { defaultProps: { mb: 'lg' } },
    Textarea: { defaultProps: { mb: 'lg' } },
    MultiSelect: { defaultProps: { mb: 'lg' } },
    Select: { defaultProps: { mb: 'lg' } },
    Switch: { defaultProps: { mb: 'lg' } },
    DatePicker: { defaultProps: { mb: 'lg' } },
    TimeInput: { defaultProps: { mb: 'lg' } },
  },
  colors: {
    // see shades section of https://www.colorhexa.com/120c18
    dark: [
      '#f4f0f7',
      '#ccbbdd',
      '#c3aed7',
      '#a587c3',
      '#39264c',
      '#2f203f',
      '#2f203f',
      '#261932',
      '#1c1325',
      '#120c18',
    ] as Tuple<string, 10>,
  },
  primaryColor: 'pink',
  primaryShade: { light: 7 as const, dark: 6 as const },
};

const RequiredAuth = () => {
  const { isAuthenticated, signin } = useAuth();

  if (!isAuthenticated) {
    signin();
    return <Loading />;
  }

  return <Outlet />;
};

const AppRoutes = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/signout" element={<SignedOutPage />} />
        <Route
          path="/login/callback"
          element={<LoginCallback loadingElement={<Loading />} />}
        />
        <Route path="/" element={<RequiredAuth />} errorElement={<ErrorPage />}>
          <Route index element={<MainPage />} />
          <Route
            path="/collection-drafts"
            element={<ListCollectionDraftsPage />}
          />
          <Route
            path="/review-requests"
            element={<ReviewRequestsDashboardPage />}
          />
          <Route path="/blacklist" element={<BlacklistPage />} />
          <Route path="/users" element={<UsersPage />} />
        </Route>
      </Routes>
    </Security>
  );
};

const App = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'dark',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          ...magicEdenTheme,
          colorScheme,
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications position="top-right" />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
