import { PublicKey } from '@solana/web3.js';
import * as v from 'superstruct';
import { address, collectionName, collectionSymbol, isodate } from '../util';
/**
 * Schema
 */
export var LaunchpadSubmissionStatus;
(function (LaunchpadSubmissionStatus) {
    LaunchpadSubmissionStatus["PENDING"] = "pending";
    LaunchpadSubmissionStatus["SUBMITTED"] = "submitted";
    LaunchpadSubmissionStatus["APPROVED"] = "approved";
    LaunchpadSubmissionStatus["REJECTED"] = "rejected";
    LaunchpadSubmissionStatus["DEPLOYED"] = "deployed";
})(LaunchpadSubmissionStatus || (LaunchpadSubmissionStatus = {}));
export var LaunchpadSubmissionState;
(function (LaunchpadSubmissionState) {
    LaunchpadSubmissionState["INTRO"] = "intro";
    LaunchpadSubmissionState["PEOPLE"] = "people";
    LaunchpadSubmissionState["DESCRIPTIONS"] = "descriptions";
    LaunchpadSubmissionState["CONTENT"] = "content";
    LaunchpadSubmissionState["GENERAL"] = "general";
    LaunchpadSubmissionState["CREATORS"] = "creators";
    LaunchpadSubmissionState["ASSETS"] = "assets";
    LaunchpadSubmissionState["METADATA"] = "metadata";
    LaunchpadSubmissionState["STAGES"] = "stages";
    LaunchpadSubmissionState["CONTRACT"] = "contract";
    LaunchpadSubmissionState["SUBMIT"] = "submit";
    LaunchpadSubmissionState["APPROVAL"] = "approval";
})(LaunchpadSubmissionState || (LaunchpadSubmissionState = {}));
export const LaunchpadBadgeDisplayNames = {
    doxxed: 'Doxxed',
    escrow_14d: 'Escrow 14d',
    escrow_7d: 'Escrow 7d',
    escrow_1d: 'Escrow 1d',
};
export const LaunchpadBadge = v.enums([
    'escrow_1d',
    'escrow_7d',
    'escrow_14d',
    'doxxed',
]);
export const LaunchpadSubmissionStateOrder = {
    [LaunchpadSubmissionState.INTRO]: 0,
    [LaunchpadSubmissionState.PEOPLE]: 1,
    [LaunchpadSubmissionState.DESCRIPTIONS]: 2,
    [LaunchpadSubmissionState.GENERAL]: 3,
    [LaunchpadSubmissionState.CONTENT]: 4,
    [LaunchpadSubmissionState.CREATORS]: 5,
    [LaunchpadSubmissionState.ASSETS]: 6,
    [LaunchpadSubmissionState.METADATA]: 7,
    [LaunchpadSubmissionState.STAGES]: 8,
    [LaunchpadSubmissionState.CONTRACT]: 9,
    [LaunchpadSubmissionState.SUBMIT]: 11,
    [LaunchpadSubmissionState.APPROVAL]: 12,
};
export const sortLaunchpadSubmissionStates = (states) => {
    return states.sort((a, b) => LaunchpadSubmissionStateOrder[a] - LaunchpadSubmissionStateOrder[b]);
};
export var AssetState;
(function (AssetState) {
    AssetState["INIT"] = "INIT";
    AssetState["MISSING"] = "MISSING";
    AssetState["QUEUED"] = "QUEUED";
    AssetState["UPLOADING"] = "UPLOADING";
    AssetState["UPLOADED"] = "UPLOADED";
    AssetState["CONFIRMED"] = "CONFIRMED";
    AssetState["FAILED"] = "FAILED";
})(AssetState || (AssetState = {}));
export const Asset = v.object({
    filename: v.string(),
    uri: v.optional(v.string()),
    timestamp: isodate(v.string()),
    size: v.min(v.integer(), 1),
    status: v.enums(Object.values(AssetState)),
    hash: v.string(),
    type: v.string(),
});
export const mergeAssets = (...assetLists) => {
    const map = new Map();
    for (const assets of assetLists) {
        for (const asset of assets) {
            map.set(asset.hash, asset);
        }
    }
    return Array.from(map.values());
};
export const WhitelistEntry = v.object({
    address: address(v.string()),
    userLimit: v.optional(v.number()),
    discord: v.optional(v.object({
        username: v.string(),
    })),
    twitter: v.optional(v.object({
        username: v.string(),
    })),
});
export const LaunchpadDraftStage = v.object({
    _id: v.string(),
    name: v.string(),
    displayName: v.string(),
    startTime: isodate(v.string()),
    endTime: isodate(v.string()),
    price: v.number(),
    walletLimit: v.optional(v.integer()),
    whitelist: v.optional(v.array(WhitelistEntry)),
    type: v.enums(['NormalSale', 'Raffle']),
});
export const parseWhitelistEntryLine = (line) => {
    const parts = line.split(',').map(s => s.trim());
    if (parts.length === 1) {
        return {
            address: parts[0],
        };
    }
    else if (parts.length === 2) {
        return {
            address: parts[0],
            userLimit: parseInt(parts[1], 10),
        };
    }
    throw new Error(`Invalid whitelist line entry: ${line}`);
};
export const parseStageWhitelist = (whitelistString) => {
    return whitelistString
        .split('\n')
        .filter(s => s.trim().length > 0)
        .map(parseWhitelistEntryLine);
};
export const getDuplicateWhitelistAddresses = (whitelist) => {
    const addressCounts = new Map();
    for (const address of whitelist) {
        addressCounts.set(address, (addressCounts.get(address) ?? 0) + 1);
    }
    const duplicates = [];
    for (const [address, count] of addressCounts.entries()) {
        if (count > 1) {
            duplicates.push(address);
        }
    }
    return duplicates;
};
export const getInvalidWhitelistAddresses = (whitelist) => {
    const invalidAddresses = [];
    for (const address of whitelist) {
        try {
            new PublicKey(address);
        }
        catch {
            invalidAddresses.push(address);
        }
    }
    return invalidAddresses;
};
export const validateStages = (stages) => {
    const errors = stages.map(stage => {
        const [err, _] = v.validate(stage, LaunchpadDraftStage);
        if (err) {
            return err.message;
        }
        return null;
    });
    for (let i = 0; i < stages.length; i += 1) {
        const currentStage = stages.at(i);
        const nextStage = stages.at(i + 1);
        if (!currentStage)
            continue;
        if (currentStage.startTime > currentStage.endTime) {
            errors.push(`${currentStage.displayName}: Start time must be before end time`);
        }
        if (!nextStage)
            continue;
        if (nextStage.startTime < currentStage.endTime) {
            errors.push(`${nextStage.displayName}: Start time must be after end time of ${currentStage.displayName}`);
        }
    }
    return errors.filter((err) => err !== null);
};
export const CreatorStruct = v.object({
    _id: v.string(),
    address: address(v.string()),
    share: v.size(v.integer(), 0, 100),
});
export const LaunchpadDraftStruct = v.object({
    /**
     * Mongoose
     */
    _id: v.union([v.string(), v.any()]),
    createdAt: isodate(v.string()),
    updatedAt: isodate(v.string()),
    deletedAt: v.optional(isodate(v.string())),
    /**
     * Draft state
     */
    submissionStatus: v.enums(Object.values(LaunchpadSubmissionStatus)),
    /**
     * Audit trail
     */
    operations: v.array(v.object({
        opType: v.enums(['approve', 'reject', 'submit']),
        performedAt: isodate(v.string()),
        message: v.optional(v.string()),
    })),
    /**
     * Used in UI to determine sections that have been completed
     */
    visitedStates: v.array(v.enums(Object.values(LaunchpadSubmissionState))),
    completedStates: v.array(v.enums(Object.values(LaunchpadSubmissionState))),
    /**
     * Used to keep track of revision
     */
    version: v.integer(),
    /**
     * Creator portal relationships to organisation and author
     * Used during review
     */
    organization: v.string(),
    author: v.string(),
    /**
     * General collection info
     */
    name: collectionName,
    symbol: collectionSymbol,
    nftSymbol: v.size(v.string(), 1, 10),
    description: v.size(v.string(), 10, 1000),
    /**
     * - Image on Launchpad page
     * - Root CID to keep track of uploaded images and metadata
     * - Preferred Gateway url
     *   - nft.storage if it's a video
     *   - cloudflare for everything else
     */
    assets: v.object({
        launchImage: v.string(),
        rootCID: v.string(),
        revealCID: v.optional(v.string()),
        gateway: v.string(),
        assetUploadCache: v.array(Asset),
    }),
    /**
     * Contract details
     *   - preferred mint date, filled in by a user
     *   - total number of mints, used for validation
     *   - treasury wallet of user, this should be a verified FTX tier 2 account
     *   - royalty points, needs review
     *   - custom SPL token address to use (eg. USDC)
     */
    signer: address(v.string()),
    expectedMintDate: isodate(v.string()),
    totalSupply: v.size(v.integer(), 1, 50000),
    treasury: v.string(),
    sellerFeeBasisPoints: v.size(v.integer(), 0, 10000),
    splTokenMint: v.nullable(address(v.string())),
    /**
     * List of creators + ME treasury with up to 10% share
     */
    creators: v.array(CreatorStruct),
    /**
     * Launch stages
     * - Name is used in notary to associate whitelist
     * - Display name is used in Launchpad UI
     * - start and end time - used in notary to sign and in contract to verify
     * - price is variable between stages
     * - wallet limit is distinct to normal sale stages, but all raffles share a wallet limit
     * - whitelist is a list of solana addresses permitted to mint in a given stage
     * - type of stage, either normal sale or raffle sale
     * - currently contract supports up to 5 distinct stages
     */
    stages: v.size(v.array(LaunchpadDraftStage), 1, 5),
    /**
     * Desired number of mints to pre mint before launchpad starts
     * Filled by the user
     */
    premintCount: v.optional(v.integer()),
    premintRecipient: v.optional(address(v.string())),
    /**
     * A flag that signifies that there is a need for reveal after mint has completed
     */
    requiresMintReveal: v.optional(v.boolean()),
    /**
     * Post creation
     * - candy machine id - set after deploy
     * - escrow (ME) treasury used to collect launchpad funds until after launchpad is complete
     */
    mainnet: v.optional(v.object({
        candyMachineId: v.string(),
        escrow: address(v.string()),
    })),
    devnet: v.optional(v.object({
        candyMachineId: v.string(),
        escrow: address(v.string()),
    })),
    /**
     * Trust & safety
     */
    links: v.object({
        twitter: v.optional(v.string()),
        discord: v.optional(v.string()),
        telegram: v.optional(v.string()),
        website: v.optional(v.string()),
        whitepaper: v.optional(v.string()),
    }),
    richDescription: v.string(),
    richRoadmap: v.string(),
    /**
     * More structured way of collection information about the team
     * - `doxxed` must only be updated by ops
     * - `refId` is used to link persona case to the form
     */
    people: v.array(v.object({
        _id: v.optional(v.string()),
        name: v.string(),
        position: v.string(),
        bio: v.optional(v.string()),
        doxxed: v.boolean(),
        twitterLink: v.optional(v.string()),
        linkedinLink: v.optional(v.string()),
        websiteLink: v.optional(v.string()),
        type: v.enums(['team', 'investor', 'advisor']),
    })),
    /**
     * escrow_1d and doxxed are mandatory
     */
    badges: v.array(LaunchpadBadge),
});
export const LaunchpadPatchStruct = v.type(v.partial(v.omit(LaunchpadDraftStruct, ['_id'])).schema);
