import { isRecord } from '@creatorhub/shared/util';
import { getAPIHost, getMEURL } from '@creatorhub/shared/services';
import axios from 'axios';
import notifications from 'src/utils/notifications';
/**
 * Get HTTP client for Creator Hub API
 */
export const getHttpClient = (options = {}) => {
    const { headers: headersOverride, ...restOptions } = options;
    return axios.create({
        baseURL: getAPIHost(),
        timeout: 30 * 1000,
        headers: {
            ...headersOverride,
            'x-client': 'admin-hub',
        },
        ...restOptions,
    });
};
/**
 * Get HTTP client for Magic Eden API
 */
export const getMagicEdenHttpClient = (options = {}) => {
    const { headers: headersOverride, ...restOptions } = options;
    return axios.create({
        baseURL: getMEURL(),
        timeout: 30 * 1000,
        headers: {
            ...headersOverride,
            'x-client': 'admin-hub',
        },
        ...restOptions,
    });
};
export const getQueryPathFromQueryKey = (queryKey) => {
    let endpointPath = queryKey.join('/');
    let queryStringParams = '';
    const optionalParamsObj = queryKey[queryKey.length - 1];
    if (isRecord(optionalParamsObj)) {
        if (Object.keys(optionalParamsObj).length > 0) {
            for (const paramKey in optionalParamsObj) {
                const paramValue = optionalParamsObj[paramKey];
                if (typeof paramValue === 'object' && paramValue !== null) {
                    console.warn(`key \`${paramKey}\` has nested object, nested params is not supported in default query function, consider using custom query function in useQuery`);
                }
            }
            queryStringParams =
                '?' + new URLSearchParams(optionalParamsObj).toString();
        }
        endpointPath = queryKey.slice(0, -1).join('/');
    }
    return [endpointPath, queryStringParams];
};
/**
 * Error utility functions
 */
const DEFAUL_ERR_MSG = 'something went wrong, contact creator hub engineers';
export const handleHttpClientError = (err) => {
    if (axios.isAxiosError(err)) {
        if (err.message === 'failed to authenticate') {
            notifications.warning('You are not logged in or your session has expired, you will be redirected to the login page');
            window.location.href = '/login';
            return;
        }
        if (err.message === 'Network Error') {
            notifications.warning('Cannot connect to the server, contact creator hub engineers');
            return;
        }
        if (err.response) {
            notifications.error(err.response?.data?.msg ||
                err.response?.data?.message ||
                `${err.response?.status} ${err.response?.statusText}`);
            return;
        }
        notifications.error(DEFAUL_ERR_MSG);
    }
    else if (err instanceof Error) {
        notifications.error(err.message || DEFAUL_ERR_MSG);
    }
    else {
        notifications.error(DEFAUL_ERR_MSG);
    }
};
