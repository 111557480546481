import { ICollectionDraftResponse } from '@creatorhub/shared/creators';
import { DotNestedLeaves } from '@creatorhub/shared/util';
import {
  Anchor,
  Text,
  SimpleGrid,
  Mark,
  List,
  Button,
  Collapse,
  Stack,
} from '@mantine/core';
import _get from 'lodash-es/get';
import _replace from 'lodash-es/replace';
import _snakeCase from 'lodash-es/snakeCase';
import { MeTextAddress } from 'src/components/MeTextAddress';
import { MeImage } from 'src/components/MeImage';
import { useDisclosure } from '@mantine/hooks';

/**
 * Type defenitions
 */

type CollectionDraftDataProps = {
  draft: ICollectionDraftResponse;
  approved?: ICollectionDraftResponse | null;
};

type FieldDiffProp<T> = {
  label?: string;
  keyPath: DotNestedLeaves<T>;
  newValues: T;
  prevValues?: T;
  valueType?: keyof typeof MODIFIED_FN;
};

type ArrayFieldDiffProp = {
  label?: string;
  keyPath: string;
  newValues: any;
  prevValues?: any;
  valueType?: keyof typeof MODIFIED_FN;
  showUnchanged?: boolean;
  hideTitle?: boolean;
};

/**
 * Helper function
 */

const keyToLabel = (key: any) => _replace(_snakeCase(key), /[._]/g, ' ');

const MODIFIED_FN = Object.freeze({
  noModification: (val: any) => `${val}`,
  shortMintAddress: (val: any) =>
    val ? <MeTextAddress address={val} inherit shorten /> : null,
  image: (val: any) => (val ? <MeImage src={val} size="small" /> : null),
  url: (val: any) => (val ? <Anchor href={val}>{val}</Anchor> : null),
  twitter: (val: any) =>
    val ? (
      <Anchor
        target="_blank"
        rel="noreferrer"
        href={`https://twitter.com/${val}`}
      >
        twitter.com/{val}
      </Anchor>
    ) : null,
  discord: (val: any) =>
    val ? (
      <Anchor href={`https://discord.gg/${val}`}>discord.gg/{val}</Anchor>
    ) : null,
});

/**
 * Components
 */

const FieldDiff = <T,>(props: FieldDiffProp<T>) => {
  const valueModifierFn = MODIFIED_FN[props.valueType ?? 'noModification'];
  const value = _get(props.newValues, props.keyPath);
  const prev = _get(props.prevValues, props.keyPath);
  return (
    <>
      <Text weight={700}>{props.label ?? keyToLabel(props.keyPath)}:</Text>
      <Text>
        {props.prevValues && value !== prev ? (
          <Mark>{valueModifierFn(value)}</Mark>
        ) : (
          valueModifierFn(value)
        )}
      </Text>
      <Text color="dimmed">
        {props.prevValues && prev !== value ? valueModifierFn(prev) : null}
      </Text>
    </>
  );
};

const ArrayFieldDiff = (props: ArrayFieldDiffProp) => {
  const valueModifierFn = MODIFIED_FN[props.valueType ?? 'noModification'];

  const newValues: any[] = _get(props.newValues, props.keyPath) ?? [];
  const prevValues: any[] = _get(props.prevValues, props.keyPath) ?? [];

  const unchangedValues: any[] = [];
  const addedValues: any[] = [];

  const newValuesSet = new Set(newValues);
  const prevValuesSet = new Set(prevValues);

  const unchangedArray = newValues.every(item => prevValuesSet.has(item));

  for (const val of newValuesSet) {
    if (prevValuesSet.has(val)) {
      unchangedValues.push(val);
    } else {
      addedValues.push(val);
    }
  }
  const removedValues: any[] = prevValues.filter(
    item => !newValuesSet.has(item),
  );

  return (
    <>
      {props.hideTitle ? (
        <></>
      ) : (
        <Text weight={700}>{props.label ?? keyToLabel(props.keyPath)}:</Text>
      )}

      <div>
        {addedValues.length > 0 && (
          <List>
            {addedValues.map((item, idx) => (
              <List.Item icon={'+'} key={idx}>
                <Text color="green">{valueModifierFn(item)}</Text>
              </List.Item>
            ))}
          </List>
        )}
        {removedValues.length > 0 && (
          <List>
            {removedValues.map((item, idx) => (
              <List.Item icon={'-'} key={idx}>
                <Text color="red">{valueModifierFn(item)}</Text>
              </List.Item>
            ))}
          </List>
        )}
        {props.showUnchanged && unchangedValues.length > 0 && (
          <List>
            {unchangedValues.map((item, idx) => (
              <List.Item icon={'='} key={idx}>
                {valueModifierFn(item)}
              </List.Item>
            ))}
          </List>
        )}
      </div>
      <List>
        {!unchangedArray &&
          prevValues.map((item, idx) => (
            <List.Item icon={'='} key={idx}>
              <Text color="dimmed">{valueModifierFn(item)}</Text>
            </List.Item>
          ))}
      </List>
    </>
  );
};

const CollapsibleArrayFieldDiff = (props: ArrayFieldDiffProp) => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <>
      <Text weight={700}>{props.label ?? keyToLabel(props.keyPath)}:</Text>

      <Stack>
        <Button onClick={toggle}>
          {opened ? 'Hide' : 'Show'} all {props.keyPath + 's'}
        </Button>

        <Collapse in={opened}>
          <ArrayFieldDiff
            keyPath={props.keyPath}
            newValues={props.newValues}
            prevValues={props.prevValues}
            valueType={props.valueType}
            hideTitle={true}
          />
        </Collapse>
      </Stack>
    </>
  );
};

export const CollectionDraftViewer = (props: CollectionDraftDataProps) => {
  return (
    <SimpleGrid cols={3} mb="lg">
      <div></div>
      <Text color="dimmed">current</Text>
      <Text color="dimmed">last approved</Text>
      <FieldDiff
        label="last submitted"
        keyPath="submittedAt"
        newValues={props.draft}
      />
      <FieldDiff
        label="last approved"
        keyPath="approvedAt"
        newValues={props.draft}
      />
      <FieldDiff
        label="pfp"
        keyPath="assets.profileImage"
        newValues={props.draft}
        prevValues={props.approved}
        valueType="image"
      />
      <FieldDiff
        label="creator email"
        keyPath="author"
        newValues={props.draft}
        // TODO: fix approved-collection-draft returns _id instead of email in author prop
      />
      <FieldDiff
        keyPath="applyPurpose"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="name"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="symbol"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="description"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        label="legal permission"
        keyPath="permission"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="totalSupply"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="categories.primary"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="categories.secondary"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="symbol"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="similarMints.maxScore"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="links.twitter"
        newValues={props.draft}
        prevValues={props.approved}
        valueType="twitter"
      />
      <FieldDiff
        keyPath="links.discord"
        newValues={props.draft}
        prevValues={props.approved}
        valueType="discord"
      />
      <FieldDiff
        keyPath="links.website"
        newValues={props.draft}
        prevValues={props.approved}
        valueType="url"
      />
      <ArrayFieldDiff
        keyPath="source"
        newValues={props.draft}
        prevValues={props.approved}
        showUnchanged
      />
      <FieldDiff
        keyPath="tokenStandard"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="mintDate"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="onChainCollectionAddress"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <FieldDiff
        keyPath="masterEditionPda"
        newValues={props.draft}
        prevValues={props.approved}
      />
      <ArrayFieldDiff
        keyPath="candyMachineIds"
        newValues={props.draft}
        prevValues={props.approved}
        showUnchanged
      />
      <CollapsibleArrayFieldDiff
        keyPath="mint"
        newValues={props.draft}
        prevValues={props.approved}
        valueType="shortMintAddress"
      />
    </SimpleGrid>
  );
};
