import { useMutation } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';
export const useCreateCollectionMutation = (opts) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const createCollection = async (variables) => {
        const res = await getHttpClient({
            headers,
        }).post('/api/admin/collections', variables.body);
        return res.data;
    };
    return useMutation(createCollection, {
        onError: handleHttpClientError,
        ...opts,
    });
};
export const useUpdateCollectionMutation = (opts) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const updateCollection = async (variables) => {
        const res = await getHttpClient({ headers }).patch(`/api/admin/collection-drafts/${variables.draftId}/collection`, variables.body);
        return res.data;
    };
    return useMutation(updateCollection, opts);
};
