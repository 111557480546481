import { useMutation } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';
export const useCreateBlacklistMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const createBlacklist = async (variables) => {
        const res = await getHttpClient({ headers }).post('/api/admin/blacklist/new', variables.body);
        return res.data;
    };
    return useMutation(createBlacklist, {
        onError: handleHttpClientError,
        ...opts,
    });
};
export const useBulkDeleteBlacklistMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const bulkDeleteBlacklist = async (variables) => {
        const res = await getHttpClient({ headers }).post('/api/admin/blacklist/bulk-delete', variables.body);
        return res.data;
    };
    return useMutation(bulkDeleteBlacklist, {
        onError: handleHttpClientError,
        ...opts,
    });
};
