import {
  ArtPermissions,
  CollectionCategories,
  IAdminCollectionDraft,
  IMeCollection,
} from '@creatorhub/shared/creators';
import {
  CollectionDraftUpdatePayload,
  CollectionDraftUpdateSchema,
  CollectionUpdatePayload,
  CollectionUpdateSchema,
} from '@creatorhub/shared/admin';
import {
  Button,
  Drawer,
  Text,
  Textarea,
  TextInput,
  Switch,
  Select,
  JsonInput,
  Tabs,
  Container,
  NumberInput,
  Group,
  Checkbox,
  Loader,
  Grid,
} from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useState } from 'react';
import { MeSwitch } from 'src/components/MeSwitch';
import { structForm } from 'src/utils/superstruct-form-validation';
import { CollectionBadges } from 'src/components/CollectionBadges';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { MeTextHint } from 'src/components/MeTextHint';
import { MeImage } from 'src/components/MeImage';
import { useAuth } from 'src/hooks/useAuth';
import { checkSymbolUniqueness } from 'src/utils/collections';

type CollectionDraftForm = {
  draftId: string;
  form: UseFormReturnType<any>;
  onSubmit: (params: {
    draftId: string;
    body: CollectionDraftUpdatePayload;
  }) => void;
  isLoading?: boolean;
};

/**
 * Form to edit collection draft on the CH side
 * update can be pushed to change the collection document
 * on the marketplace side
 */
const CollectionDraftForm = (props: CollectionDraftForm) => {
  const { authorization } = useAuth();
  const [isDerivative, setIsDerivative] = useState(
    Boolean(props.form.values.derivative),
  );

  const [symbolError, setSymbolError] = useState('');
  const [checkingSymbol, setCheckingSymbol] = useState(false);

  async function validateUniqueSymbol(symbol: string) {
    if (validateSymbol(symbol) != '') {
      return;
    }
    setCheckingSymbol(true);
    const res = await checkSymbolUniqueness({
      authorization,
      symbol: symbol,
      draftId: props.draftId,
    });
    setCheckingSymbol(false);
    if (res.duplicate) {
      const error =
        'Symbol is already taken, you have to choose an unique collection symbol to generate your collection URL on Magic Eden.';
      setSymbolError(error);
    }
  }

  function updateSymbol(value: string) {
    props.form.setFieldValue('symbol', value);
    setSymbolError(validateSymbol(value));
  }

  function validateSymbol(value: string) {
    let error = '';
    if (value.length < 3 || value.length > 64) {
      error = 'Symbol must be between 3 and 64 characters long';
      setSymbolError(error);
    }

    if (/[^a-z0-9_-]/gi.test(value)) {
      error = 'Alphanumeric and _ characters allowed only';
      setSymbolError(error);
    }

    return error;
  }

  return (
    <form
      onSubmit={props.form.onSubmit(formValues => {
        props.onSubmit({
          draftId: props.draftId,
          body: formValues,
        });
      })}
    >
      <TextInput
        label="Symbol"
        {...props.form.getInputProps('symbol')}
        error={symbolError !== '' ? symbolError : null}
        onChange={event => updateSymbol(event.currentTarget.value)}
        onBlur={event => validateUniqueSymbol(event.currentTarget.value)}
        rightSection={checkingSymbol ? <Loader size="xs" /> : null}
      />
      <TextInput label="Name" {...props.form.getInputProps('name')} />
      <Textarea
        label="Description"
        {...props.form.getInputProps('description')}
      />
      <Select
        label="Art Permission"
        clearable
        data={Object.values(ArtPermissions)}
        {...props.form.getInputProps('permission')}
      />
      <TextInput
        label="Image URL"
        {...props.form.getInputProps('assets.profileImage')}
      />
      {props.form.values.assets?.profileImage && (
        <MeImage
          mb="lg"
          size="medium"
          src={props.form.values.assets.profileImage}
        />
      )}
      <Select
        label="Primary Category"
        clearable
        data={Object.values(CollectionCategories).map(category => ({
          value: category,
          label: category,
          disabled: category === props.form.values.categories?.secondary,
        }))}
        {...props.form.getInputProps('categories.primary')}
      />
      <Select
        label="Secondary Category (optional)"
        clearable
        data={Object.values(CollectionCategories).map(category => ({
          value: category,
          label: category,
          disabled: category === props.form.values.categories?.primary,
        }))}
        {...props.form.getInputProps('categories.secondary')}
      />
      <Group>
        <DatePickerInput
          label="Mint Date"
          {...props.form.getInputProps('mintDate')}
          value={
            props.form.values.mintDate && new Date(props.form.values.mintDate)
          }
          onChange={(val: Date) => {
            const prevMintDate = new Date(props.form.values.mintDate);
            const mintDate = new Date(
              val.getFullYear(),
              val.getMonth(),
              val.getDate(),
              prevMintDate.getHours(),
              prevMintDate.getMinutes(),
              prevMintDate.getSeconds(),
              prevMintDate.getMilliseconds(),
            );
            props.form.setFieldValue('mintDate', mintDate);
          }}
        />
        <TimeInput
          label="Mint Time"
          {...props.form.getInputProps('mintDate')}
          value={
            props.form.values.mintDate && new Date(props.form.values.mintDate)
          }
          onChange={event => {
            const val = new Date(event.target.value);
            const prevMintDate = new Date(props.form.values.mintDate);
            const newValue = new Date(
              prevMintDate.getFullYear(),
              prevMintDate.getMonth(),
              prevMintDate.getDate(),
              val.getHours(),
              val.getMinutes(),
              val.getSeconds(),
              val.getMilliseconds(),
            );
            props.form.setFieldValue('mintDate', newValue);
          }}
        />
        <Button
          variant="subtle"
          color="orange"
          compact
          onClick={() => props.form.setFieldValue('mintDate', null)}
        >
          Reset Mint Date Value
        </Button>
      </Group>
      <TextInput
        label="Website"
        {...props.form.getInputProps('links.website')}
      />
      <TextInput
        label="Twitter"
        {...props.form.getInputProps('links.twitter')}
      />
      <TextInput
        label="Discord"
        {...props.form.getInputProps('links.discord')}
      />
      <TextInput
        label="Mint link"
        {...props.form.getInputProps('links.mint')}
      />
      <MeSwitch
        label="Is Verified"
        {...props.form.getInputProps('isVerified')}
      />
      <Switch
        label="Is Derivative"
        onChange={event => setIsDerivative(event.currentTarget.checked)}
      />
      {isDerivative && (
        <>
          <TextInput
            disabled={!isDerivative}
            label="Derivative Original Collection Name"
            {...props.form.getInputProps('derivativeDetails.originName')}
          />
          <TextInput
            disabled={!isDerivative}
            label="Link to Original Collection"
            {...props.form.getInputProps('derivativeDetails.originLink')}
          />
        </>
      )}
      <NumberInput
        label="Total Supply"
        {...props.form.getInputProps('totalSupply')}
        min={0}
        defaultValue={0}
        precision={0}
      />
      <TextInput
        label="Master Edition PDA"
        {...props.form.getInputProps('masterEditionPda')}
      />
      <JsonInput
        label="Candy Machine IDs"
        {...props.form.getInputProps('candyMachineIds')}
      />
      {/* TODO: do we need overwrite flag? */}
      <JsonInput label="Hash List" {...props.form.getInputProps('mint')} />
      <ErrorMessage errors={props.form.errors} />
      <Group align="center">
        <Button
          type="submit"
          loading={props.isLoading}
          disabled={!props.form.isDirty()}
        >
          Update Draft
        </Button>
        <Checkbox
          label="apply changes to listed collection"
          {...props.form.getInputProps('shouldUpdateCollection')}
        />
      </Group>
      <MeTextHint>
        this will update {props.form.values.symbol} collection draft{' '}
        {props.form.values.shouldUpdateCollection &&
          'and marketplace collection with the same symbol '}
      </MeTextHint>
    </form>
  );
};

type ListedCollectionFormProps = {
  draftId: string;
  form: UseFormReturnType<any>;
  onSubmit: (params: {
    draftId: string;
    body: CollectionUpdatePayload;
  }) => void;
  isLoading?: boolean;
};

/**
 * Form to edit collection directly on the marketplace side
 */
const ListedCollectionForm = (props: ListedCollectionFormProps) => {
  return (
    <form
      onSubmit={props.form.onSubmit(formValues => {
        props.onSubmit({ draftId: props.draftId, body: formValues });
      })}
    >
      <TextInput label="Iframe link" {...props.form.getInputProps('iframe')} />
      <MeSwitch
        label="Enable Attributes Filter"
        {...props.form.getInputProps('enabledAttributesFilters')}
      />
      <MeSwitch
        label="Enable Version Filter"
        {...props.form.getInputProps('enabledVersionFilter')}
      />
      <MeSwitch
        label="Is Verified"
        {...props.form.getInputProps('isVerified')}
      />
      <MeSwitch
        label="Enable Moonrank Rank"
        {...props.form.getInputProps('rarity.showMoonrank')}
      />
      <MeSwitch
        label="Enable Howrare.is Rank"
        {...props.form.getInputProps('rarity.showHowrare')}
      />
      <MeSwitch
        label="Enable Magic Eden Rank"
        {...props.form.getInputProps('rarity.showMagicEden')}
      />
      <MeSwitch
        label="Enable Total Supply"
        {...props.form.getInputProps('enabledTotalSupply')}
      />
      <MeSwitch
        label="Enable Unique Owners"
        {...props.form.getInputProps('enabledUniqueOwners')}
      />
      <MeSwitch
        label="Exclude From Popular Collections"
        {...props.form.getInputProps('excludeFromPopularCollections')}
      />
      <MeSwitch label="Is Draft" {...props.form.getInputProps('isDraft')} />
      <Select
        label="NFT Image Type"
        clearable
        data={['jpeg', 'gif', 'mp4']}
        {...props.form.getInputProps('nftImageType')}
      />
      <TextInput
        label="MCC address"
        description="on-chain collection address"
        {...props.form.getInputProps('onChainCollectionAddress')}
      />
      <JsonInput
        label="Blocked Mints"
        {...props.form.getInputProps('blockedMints')}
      />
      <JsonInput
        label="Black List Traits"
        description="will ignore them for rarity calculation"
        {...props.form.getInputProps('blackListAttributes')}
      />
      {/*
          looks like it is not in use, remove after December 2022
          <JsonInput
            label="Stack By"
            {...props.form.getInputProps('onChainCollectionAddress')}
          />
        */}
      {/* TODO: add mutation loagin disabled props to the button to prevent multiple submissions */}
      <Button
        type="submit"
        loading={props.isLoading}
        disabled={!props.form.isDirty()}
      >
        Update Collection
      </Button>
    </form>
  );
};

type CollectionDraftsEditorTabs =
  | 'COLLECTION_DRAFT'
  | 'LISTED_COLLECTION'
  | 'COLLECTION_FLAGS';

type CollectionDraftsEditorProps = {
  collection?: IMeCollection | null;
  draft: IAdminCollectionDraft;
  internalReview?: { note?: string };
  onDraftSubmit: (params: {
    draftId: string;
    body: CollectionDraftUpdatePayload;
  }) => void;
  onCollectionSubmit: (params: {
    draftId: string;
    body: CollectionUpdatePayload;
  }) => void;
  onInternalReviewSubmit: (variables: {
    body: { id: string; note: string };
  }) => void;
  onClose: () => void;
  loading: boolean;
};

export const CollectionDraftsEditor = (props: CollectionDraftsEditorProps) => {
  const [activeTab, setActiveTab] =
    useState<CollectionDraftsEditorTabs>('COLLECTION_DRAFT');

  const collectionDraftForm = useForm<any>(
    structForm(
      {
        links: {},
        ...props.draft,
        shouldUpdateCollection: false,
        // JSON to string transformation, these form fields expecting string values
        candyMachineIds: JSON.stringify(
          props.draft.candyMachineIds || [],
          null,
          2,
        ),
        mint: JSON.stringify(props.draft.mint || [], null, 2),
      },
      CollectionDraftUpdateSchema,
    ),
  );

  const listedCollectionForm = useForm<any>(
    structForm(
      {
        ...props.collection,
        blackListAttributes: JSON.stringify(props.draft.mint || [], null, 2),
        blockedMints: JSON.stringify(props.draft.mint || [], null, 2),
        rarity: {
          showMoonrank: false,
          showHowrare: false,
          showMagicEden: false,
        },
      },
      CollectionUpdateSchema,
    ),
  );

  const internalReviewForm = useForm<{ note: string }>({
    initialValues: {
      note: '',
      ...props.internalReview,
    },
  });

  return (
    <Drawer
      opened
      onClose={() => {
        if (!listedCollectionForm.isDirty() || confirm('discard editing?')) {
          props.onClose();
        }
      }}
      title={
        <Group>
          {`Edit \`${props.draft.symbol}\` Collection`}
          <CollectionBadges draft={props.draft} collection={props.collection} />
        </Group>
      }
      padding="lg"
      size="1080px"
      //styles={{ drawer: { overflowY: 'scroll' } }}
    >
      <Text mb="md">
        creator&apos;s comment:{' '}
        <Text span color="dimmed" mb="md">
          {props.draft.comment || 'no comment'}
        </Text>
      </Text>
      <form
        onSubmit={internalReviewForm.onSubmit(values =>
          props.onInternalReviewSubmit({
            body: {
              id: props.draft._id,
              note: values.note,
            },
          }),
        )}
      >
        <Group>
          <Textarea
            label="internal review note"
            placeholder="no note"
            mb="xl"
            cols={60}
            {...internalReviewForm.getInputProps('note')}
          />
          <Button
            type="submit"
            loading={props.loading}
            disabled={!internalReviewForm.isDirty()}
          >
            Update
          </Button>
        </Group>
      </form>
      <Grid>
        <Grid.Col span={12}>
          <Tabs value={activeTab} onTabChange={setActiveTab as any}>
            <Tabs.List>
              <Tabs.Tab value="COLLECTION_DRAFT">Draft</Tabs.Tab>
              <Tabs.Tab value="COLLECTION_FLAGS" disabled>
                Collection Flags (not yet available)
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="COLLECTION_DRAFT">
              <Container mt="xl">
                <CollectionDraftForm
                  draftId={props.draft._id}
                  form={collectionDraftForm}
                  onSubmit={props.onDraftSubmit}
                  isLoading={props.loading}
                />
              </Container>
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
        <Grid.Col span={1}>
          <Tabs value={activeTab} onTabChange={setActiveTab as any}>
            <Tabs.List>
              <Tabs.Tab
                value="LISTED_COLLECTION"
                disabled={!props.collection || !listedCollectionForm}
              >
                Collection
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="LISTED_COLLECTION">
              {listedCollectionForm && (
                <Container mt="xl">
                  <ListedCollectionForm
                    draftId={props.draft._id}
                    form={listedCollectionForm}
                    onSubmit={props.onCollectionSubmit}
                    isLoading={props.loading}
                  />
                </Container>
              )}
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Drawer>
  );
};
