import { Text } from '@mantine/core';
import { ReactNode } from 'react';
import { TbInfoCircle } from 'react-icons/tb';

export const MeTextHint = (props: { children: ReactNode }) => {
  return (
    <Text color="dimmed" size="sm">
      <TbInfoCircle size="16" /> {props.children}
    </Text>
  );
};
