import {
  Button,
  Group,
  Image,
  Skeleton,
  Text,
  Card,
  Badge,
} from '@mantine/core';
import { IconExternalLink } from '@tabler/icons';

import { useState } from 'react';

import { getListNftsByMintAddresses, useMagicEdenQuery } from 'src/queries';
import { Carousel } from '@mantine/carousel';
import { INFT } from '@creatorhub/shared/metaplex/getNFTsByHashList';

type MintsCardViewerProps = {
  mints: INFT[];
};

export interface MintPreviewProps {
  mints: string[];
}
export function MintPreview(props: MintPreviewProps) {
  const limit = 5;
  const [offset, setOffset] = useState(0);

  const mintsToView = props.mints.slice(offset, offset + limit);

  const nfts = useMagicEdenQuery(
    ['rpc', getListNftsByMintAddresses.name, mintsToView.join('_')],
    {
      queryFn: () => getListNftsByMintAddresses(mintsToView),
    },
  );

  if (!nfts.data?.length) {
    return null;
  }

  return (
    <Group>
      {nfts.data &&
        nfts.data.map((nft, idx) => (
          <Image
            key={nft?.id ?? `nft_${idx}`}
            width={100}
            height={100}
            src={nft?.img}
            alt={nft?.title}
          />
        ))}
      {nfts.isFetching &&
        Array(limit)
          .fill(null)
          .map((_, idx) => {
            return <Skeleton key={idx} width={100} height={100} />;
          })}
      {props.mints.length > offset + limit && (
        <Button
          variant="subtle"
          onClick={() => setOffset(prev => prev + limit)}
        >
          Next {limit}
        </Button>
      )}
    </Group>
  );
}
export const MintsCardViewer = (props: MintsCardViewerProps) => {
  if (!props.mints) {
    return <Text>Collection Draft has empty hash list</Text>;
  }

  return (
    <div>
      <Carousel
        withIndicators
        height={500}
        slideSize="33.333333%"
        slideGap="md"
        loop
        align="start"
        slidesToScroll={3}
      >
        {' '}
        {props.mints.map((card, index) => (
          <Carousel.Slide key={index}>
            <Card shadow="sm" p="lg" radius="md" withBorder>
              <Card.Section>
                <Image
                  withPlaceholder
                  src={card.img}
                  height={250}
                  alt={card.title}
                />
              </Card.Section>

              <Group position="apart" mt="md" mb="xs">
                <Text weight={500}>{card.title}</Text>
                <Badge color="pink" variant="light">
                  {card.score}
                </Badge>
              </Group>

              <Text size="sm" color="dimmed">
                {card.collectionTitle}
              </Text>
              <Button.Group>
                {card.collectionName && (
                  <Button
                    component="a"
                    variant="outline"
                    fullWidth
                    leftIcon={<IconExternalLink size={14} />}
                    href={`https://magiceden.io/marketplace/${card.collectionName}`}
                    target="_blank"
                    rel="noreferer"
                  >
                    Marketplace
                  </Button>
                )}
                <Button
                  component="a"
                  variant="outline"
                  fullWidth
                  leftIcon={<IconExternalLink size={14} />}
                  href={`https://magiceden.io/item-details/${card.mintAddress}`}
                  target="_blank"
                  rel="noreferer"
                >
                  Mint
                </Button>
              </Button.Group>
            </Card>
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};
