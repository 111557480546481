import { ActionIcon, CopyButton, Tooltip } from '@mantine/core';
import { TbCheck, TbCopy } from 'react-icons/tb';

export const MeCopyButton = (props: { value: any }) => {
  return (
    <CopyButton value={props.value} timeout={1000}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow>
          <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
            {copied ? <TbCheck size={16} /> : <TbCopy size={16} />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
};
