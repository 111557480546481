import { Container, LoadingOverlay } from '@mantine/core';

type LoadingProps = {
  // numbers 0..1
  overlayOpacity?: number;
};

export const Loading = (props: LoadingProps) => {
  const overlayOpacity = props.overlayOpacity ?? 1;
  return (
    <Container>
      <LoadingOverlay visible overlayOpacity={overlayOpacity} />
    </Container>
  );
};
