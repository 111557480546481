import { PublicKey } from '@solana/web3.js';
export const METADATA_PREFIX = 'metadata';
const PubKeysInternedMap = new Map();
export const toPublicKey = (key) => {
    if (typeof key !== 'string') {
        return key;
    }
    let result = PubKeysInternedMap.get(key);
    if (!result) {
        result = new PublicKey(key);
        PubKeysInternedMap.set(key, result);
    }
    return result;
};
export function getAPIHost() {
    if (process.env.REACT_APP_BACKEND_URL) {
        return process.env.REACT_APP_BACKEND_URL;
    }
    if (getNetwork() === 'mainnet') {
        return 'https://api-creators.magiceden.io';
    }
    else {
        return 'https://api-creators-staging.magiceden.io';
    }
}
export function getMEURL() {
    if (process.env.ME_BACKEND_URL) {
        return process.env.ME_BACKEND_URL;
    }
    if (getNetwork() === 'mainnet') {
        return 'https://internal-api-mainnet.magiceden.dev';
    }
    else {
        return 'https://internal-api-devnet.magiceden.dev';
    }
}
export function getBaseURL() {
    if (process.env.REACT_APP_CREATORHUB_BASE_URL) {
        return process.env.REACT_APP_CREATORHUB_BASE_URL;
    }
    if (getNetwork() === 'mainnet') {
        return 'https://creators.magiceden.io';
    }
    else {
        return 'https://main.creators.pages.dev';
    }
}
export function getNetwork() {
    if (process.env.REACT_APP_BACKEND_URL === 'https://api-creators.magiceden.io') {
        return 'mainnet';
    }
    return 'devnet';
}
export function getSolanaRpc() {
    if (getNetwork() === 'mainnet') {
        return 'https://api.metaplex.solana.com';
    }
    return 'https://api.devnet.solana.com';
}
export function getPresaleURL() {
    if (getEnv() === 'prod') {
        return 'https://presale.magiceden.io';
    }
    return 'https://presale-staging.magiceden.io';
}
export function getEnv() {
    const apiHost = getAPIHost();
    if (apiHost === 'https://api-creators.magiceden.io') {
        return 'prod';
    }
    if (apiHost === 'https://api-creators-staging.magiceden.io') {
        return 'staging';
    }
    return 'local';
}
export function getRudderParams() {
    if (getNetwork() === 'mainnet') {
        return [
            '1zTsCufv8CEl4Vx9J529BN5K2Wu',
            'https://sanur-rsd.magiceden.workers.dev',
            {
                configUrl: 'https://sanur-rsa.magiceden.workers.dev',
                sessions: {
                    autoTrack: true,
                },
            },
        ];
    }
    return [
        '1zTm6HtHpasWNbVF1JrZ4wnxpxt',
        'https://sanur-rsd.magiceden.workers.dev',
        {
            configUrl: 'https://sanur-rsa.magiceden.workers.dev',
            sessions: {
                autoTrack: true,
            },
        },
    ];
}
export function getMEMarketplaceBaseURL() {
    if (process.env.REACT_APP_MARKETPLACE_BASE_URL) {
        return process.env.REACT_APP_MARKETPLACE_BASE_URL;
    }
    if (getNetwork() === 'mainnet') {
        return 'https://magiceden.io/marketplace/';
    }
    return 'https://main.magiceden.pages.dev/marketplace/';
}
