import { useMutation } from 'react-query';
import { useAuth } from '../hooks';
import { getHttpClient, handleHttpClientError } from '../queries/http-client';
export const useToggleUserPresaleMutation = (opts = {}) => {
    const { authorization } = useAuth();
    const headers = {};
    if (authorization) {
        headers.authorization = authorization;
    }
    const updateUserPresale = async (variables) => {
        await getHttpClient({ headers }).patch(`/api/admin/users/${variables._id}/presale`, variables.body);
    };
    return useMutation(updateUserPresale, {
        onError: handleHttpClientError,
        ...opts,
    });
};
