import { Blockchain } from '@creatorhub/shared/creators';
import { Badge } from '@mantine/core';

type BlockchainIconProps = {
  chain: Blockchain | 'unknown';
};

export const BlockchainIcon = (props: BlockchainIconProps) => {
  if (props.chain === 'unknown') {
    return <Badge color="gray">TBD</Badge>;
  }
  return <Badge>{props.chain.slice(0, 3)}</Badge>;
};
