import { Badge } from '@mantine/core';

type TokenStandardIconProps = {
  standard: 'NFT' | 'SFT' | 'SPL' | 'NFT edition' | 'unknown';
};

export const TokenStandardIcon = (props: TokenStandardIconProps) => {
  if (props.standard === 'NFT edition') {
    return <Badge>NFT Ed.</Badge>;
  }
  if (props.standard === 'unknown') {
    return <Badge color="gray">TBD</Badge>;
  }
  return <Badge>{props.standard}</Badge>;
};
