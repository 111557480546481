import { showNotification as notification } from '@mantine/notifications';
/**
 * Admin Hub notification system
 *
 * depends on @mantine/notifications and NotificationsProvider context
 */
const info = (message) => {
    notification({ message, color: 'default' });
};
const success = (message) => {
    notification({ message, color: 'green' });
};
const warning = (message) => {
    notification({ message, color: 'orange' });
};
const error = (message) => {
    notification({ message, color: 'red' });
};
export default {
    info,
    success,
    warning,
    error,
};
