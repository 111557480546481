import { IAnnouncementDraft } from '@creatorhub/shared/creators';
import { IReviewRequest } from '@creatorhub/shared/admin';
import { formatDateTime } from '@creatorhub/shared/util';
import {
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { MeImage } from 'src/components/MeImage';

import { MeTextAddress } from 'src/components/MeTextAddress';
import { TbPhotoOff } from 'react-icons/tb';

const ACTIONS = Object.freeze(['approve', 'reject', 'archive'] as const);

type ReviewAction = (typeof ACTIONS)[number];

type ReviewRequestForm = {
  action: ReviewAction | null;
  message: string | null;
};

const deriveFormVisualState = (form: UseFormReturnType<ReviewRequestForm>) => {
  const isMessageRequired =
    form.values.action === 'reject' || form.values.action === 'archive';
  const isMessageEmpty =
    form.values.message == null || form.values.message.length === 0;

  let buttonColor = 'gray';
  let buttonText = 'Select Action';

  if (form.values.action === 'approve') {
    buttonColor = 'green';
    buttonText = 'Approve';
  }
  if (form.values.action === 'reject') {
    buttonColor = 'orange';
    buttonText = 'Reject';
  }
  if (form.values.action === 'archive') {
    buttonColor = 'red';
    buttonText = 'Archive';
  }

  return {
    disabled:
      (isMessageRequired && isMessageEmpty) ||
      !ACTIONS.includes(form.values.action as any),
    isMessageRequired,
    buttonColor,
    buttonText,
  };
};

type ReviewRequestProps = {
  request: IReviewRequest;
  isLoading?: boolean;
  onSubmit: (variables: {
    _id: string;
    collectionId: string | null;
    body: { action: string; message?: string };
  }) => void;
};

// Note: currently ReviewRequest component is designed to support
// only AnnouncementDraft resource but should be extended to bee
// polymorphic for other resources that the review pipeline will support
export const ReviewRequest = (props: ReviewRequestProps) => {
  const reviewForm = useForm<ReviewRequestForm>({
    initialValues: { action: null, message: null },
  });

  const formVisual = deriveFormVisualState(reviewForm);

  return (
    <Card p="lg" radius="sm" withBorder maw="1280px">
      <Box mb="xl">
        Review request{' '}
        <Text span color="dimmed">
          {props.request.resourceType} / {props.request.resourceId}
        </Text>
      </Box>
      <Box mb="xl">
        <Flex gap="1.5rem" align="center">
          {props.request.collectionDraft?.assets?.profileImage ? (
            <MeImage
              src={props.request.collectionDraft?.assets?.profileImage}
              size="tiny"
            />
          ) : (
            <Center>
              <TbPhotoOff size={35} />
            </Center>
          )}
          {props.request.collectionDraft ? (
            <Flex gap=".5rem" align="center">
              <Text weight={500} span>
                {props.request.collectionDraft.name}
              </Text>
              <Badge>{props.request.collectionDraft.symbol}</Badge>
            </Flex>
          ) : (
            <Text weight={500}>unknown collection</Text>
          )}
          <Text weight={500}>
            <Text color="dimmed" span>
              submitted
            </Text>{' '}
            {formatDateTime(props.request.createdAt)}
          </Text>
          <Text weight={500}>
            <Text color="dimmed" span>
              by
            </Text>{' '}
            {props.request.submitter?.email ?? 'unknown submitter'}
          </Text>
        </Flex>
      </Box>
      <Box mb="xl">
        <Flex gap="1.5rem" align="center">
          <Text weight={800}>{props.request.resource.title}</Text>
          <Text weight={500}>
            <Text color="dimmed" span>
              go live date
            </Text>{' '}
            {formatDateTime(new Date(props.request.resource.publishAt))}
          </Text>
          <Text weight={500}>
            <Text color="dimmed" span>
              end date
            </Text>{' '}
            {formatDateTime(new Date(props.request.resource.removeAt))}
          </Text>
        </Flex>
        <Text>{props.request.resource.content}</Text>
      </Box>

      <AnnouncementDraftReview diff={props.request.data} />

      <form
        onSubmit={reviewForm.onSubmit(values => {
          if (!values.action) {
            return null;
          }

          const body: { action: string; message?: string } = {
            action: values.action,
          };
          if (values.message) {
            body.message = values.message;
          }
          props.onSubmit({
            _id: props.request._id,
            collectionId: props.request.collectionDraft?.symbol ?? null,
            body,
          });
        })}
      >
        <Flex gap="1rem" align="center" mt="lg">
          <Select
            mb="none"
            data={[...ACTIONS]}
            {...reviewForm.getInputProps('action')}
          />
          {formVisual.isMessageRequired && (
            <Input
              placeholder="type the reason"
              mb="none"
              {...reviewForm.getInputProps('message')}
            />
          )}
          <Button
            w="124px"
            type="submit"
            color={formVisual.buttonColor}
            disabled={formVisual.disabled}
            loading={props.isLoading}
          >
            {formVisual.buttonText}
          </Button>
        </Flex>
      </form>
    </Card>
  );
};

/**
 * Type definitions
 */

type FieldDiffProp<T> = {
  label?: string;
  field: keyof T;
  diff: Record<keyof T, [string, string]>;
  valueType?: keyof typeof MODIFIED_FN;
};

/**
 * Helper function
 */

const MODIFIED_FN = Object.freeze({
  noModification: (val: any) => `${val}`,
  dateTime: (val: any) => formatDateTime(val),
  shortMintAddress: (val: any) =>
    val ? <MeTextAddress address={val} inherit shorten /> : null,
  image: (val: any) => (val ? <MeImage src={val} size="small" /> : null),
  url: (val: any) => (val ? <Anchor href={val}>{val}</Anchor> : null),
  twitter: (val: any) =>
    val ? (
      <Anchor
        target="_blank"
        rel="noreferrer"
        href={`https://twitter.com/${val}`}
      >
        twitter.com/{val}
      </Anchor>
    ) : null,
  discord: (val: any) =>
    val ? (
      <Anchor href={`https://discord.gg/${val}`}>discord.gg/{val}</Anchor>
    ) : null,
});

/**
 * Components
 */

const FieldDiff = <T,>(props: FieldDiffProp<T>) => {
  const valueModifierFn = MODIFIED_FN[props.valueType ?? 'noModification'];
  const prevValue = props.diff[props.field]?.at(0);
  const newValue = props.diff[props.field]?.at(1);
  if (!prevValue && !newValue) {
    return null;
  }

  return (
    <>
      <Text color="dimmed">{props.label}:</Text>
      <Text color="dimmed">previous value:</Text>
      <Text>{valueModifierFn(newValue)}</Text>
      <Text color="dimmed">
        {prevValue && prevValue !== newValue
          ? valueModifierFn(prevValue)
          : null}
      </Text>
    </>
  );
};
type AnnouncementDraftReviewProps = {
  diff: Record<keyof IAnnouncementDraft, [string, string]>;
};

const AnnouncementDraftReview = (props: AnnouncementDraftReviewProps) => {
  return (
    <SimpleGrid cols={2} mb="lg">
      <FieldDiff
        label="Collection"
        diff={props.diff}
        field="collectionDraftId"
      />
      <FieldDiff label="Headline" diff={props.diff} field="title" />
      <FieldDiff label="Message Body" diff={props.diff} field="content" />
      <FieldDiff label="Click through URL" diff={props.diff} field="linkTo" />
      <FieldDiff
        label="Go live date & time"
        diff={props.diff}
        field="publishAt"
        valueType="dateTime"
      />
      <FieldDiff
        label="Remove date & time"
        diff={props.diff}
        field="removeAt"
        valueType="dateTime"
      />
    </SimpleGrid>
  );
};
