import { Image, MantineSize } from '@mantine/core';
import {
  shouldUseCdn,
  buildCdnUrl,
  ImageSizeKeys,
  ImageSize,
} from '@creatorhub/shared/util';

type MeImageProps = {
  mb?: MantineSize;
  src: string;
  size?: ImageSizeKeys;
  disableCdn?: boolean;
};

export const MeImage = (props: MeImageProps) => {
  const size = props.size ?? 'medium';
  const imgSize = `${ImageSize[size]}px`;
  const src = shouldUseCdn({
    img: props.src,
    disableCdn: props.disableCdn,
  })
    ? buildCdnUrl(props.src, { sizeX: size, sizeY: size })
    : props.src;

  return <Image mb={props.mb} src={src} height={imgSize} width={imgSize} />;
};
