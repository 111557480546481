import * as v from 'superstruct';
import { collectionName, collectionSymbol, isodate, notEmpty } from '../util';
import { CollectionCategories, } from './shared';
export var ListingSubmissionState;
(function (ListingSubmissionState) {
    ListingSubmissionState["INTRO"] = "intro";
    ListingSubmissionState["GENERAL"] = "general";
    ListingSubmissionState["DETAILS"] = "details";
    ListingSubmissionState["PROGRAM"] = "program";
    ListingSubmissionState["SUBMIT"] = "submit";
})(ListingSubmissionState || (ListingSubmissionState = {}));
export var Sources;
(function (Sources) {
    Sources["NONE"] = "";
    Sources["CM_V2"] = "candymachine_v2";
    Sources["LAUNCHPAD"] = "launchpad";
    Sources["LMNFT"] = "launch_my_nft";
    Sources["MONKELABS"] = "monkelabs";
    Sources["NOVALAUNCH"] = "novalaunch";
    Sources["OPENSEA"] = "opensea";
    Sources["UNKNOWN"] = "unknown";
})(Sources || (Sources = {}));
export var ApplyPurposes;
(function (ApplyPurposes) {
    ApplyPurposes["PRE_APPROVAL"] = "pre_approval";
    ApplyPurposes["LISTING_NOW"] = "listing_now";
})(ApplyPurposes || (ApplyPurposes = {}));
export var Blockchain;
(function (Blockchain) {
    Blockchain["SOL"] = "solana";
    Blockchain["ETH"] = "ethereum";
    Blockchain["MATIC"] = "polygon";
    Blockchain["BASE"] = "base";
    Blockchain["BTC"] = "bitcoin";
})(Blockchain || (Blockchain = {}));
export var MmmStatus;
(function (MmmStatus) {
    MmmStatus["NONE"] = "";
    MmmStatus["AVAILABLE"] = "available";
    MmmStatus["UNAVAILABLE"] = "unavailable";
    MmmStatus["REVIEW_NEEDED"] = "review_needed";
    MmmStatus["AUTOLIST"] = "autolist";
})(MmmStatus || (MmmStatus = {}));
export var OrdLabel;
(function (OrdLabel) {
    OrdLabel["ONCHAIN"] = "ONCHAIN";
    OrdLabel["OFFCHAIN"] = "OFFCHAIN";
    OrdLabel["RECURSIVE"] = "RECURSIVE";
    OrdLabel["CURSED"] = "CURSED";
})(OrdLabel || (OrdLabel = {}));
export var ArtPermissions;
(function (ArtPermissions) {
    ArtPermissions["AUTHOR"] = "author";
    ArtPermissions["LICENSED"] = "licensed";
})(ArtPermissions || (ArtPermissions = {}));
/**
 * Constants
 */
// maximum number of hashes in mint array
export const HASH_LIST_LIMIT = 150000;
export const TOTAL_SUPPLY_LIMIT = 10_000_000;
export const LIST_NOW = 'list now';
export const UNKNOWN_MINT_DATE = 'unknown mint date';
// minimal number of twitter followers to attach to a collection draft
export const TWITTER_FOLLOWERS_THRESHOLD = 100;
/**
 * Validation
 */
export const CollectionDraftStruct = v.object({
    name: collectionName,
    symbol: collectionSymbol,
    description: v.size(v.string(), 10, 1000),
    assets: v.object({
        profileImage: v.string(),
        inscriptionIcon: v.optional(v.string()),
    }),
    derivative: v.nullable(v.object({
        originalLink: notEmpty(v.string()),
        originalName: v.size(v.string(), 3, 128),
    })),
    categories: v.object({
        primary: v.enums(Object.values(CollectionCategories)),
        secondary: v.nullable(v.enums(Object.values(CollectionCategories))),
    }),
    links: v.object({
        twitter: v.string(),
        discord: v.optional(v.string()),
        website: v.optional(v.string()),
        mint: v.optional(v.string()),
    }),
    mintDate: v.optional(v.nullable(isodate(v.string()))),
    candyMachineIds: v.array(v.string()),
    creatorTipsAddress: v.optional(v.nullable(v.string())),
});
