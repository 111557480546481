import { useQuery } from 'react-query';
import { getMagicEdenHttpClient, getQueryPathFromQueryKey, handleHttpClientError, } from './http-client';
const magicEdenApi = getMagicEdenHttpClient();
/**
 * A generic query function for react-query useQuery
 * allows to pass only query key to make a HTTP GET query to Creator Hub API
 *
 * @example
 *    // makes GET /rpc/getNFTByMintAddress HTTP query
 *    useMagicEdenQuery(['rpc', 'getNFTByMintAddress'])
 *
 * @param params
 * @param params.queryKey - array of react-query useQuery keys,
 *    to add query string pass an object with query string key values as last item
 * @param params.meta - useQuery meta object
 * @param params.meta.authorization - authorization herader, e.g. "Bearer some-auth-token", optional
 */
const magicEdenApiQueryFn = async ({ queryKey, meta = {}, }) => {
    let headers;
    if (meta.authorization) {
        headers = headers ?? {};
        headers.authorization = meta.authorization;
    }
    const [endpointPath, queryStringParams] = getQueryPathFromQueryKey(queryKey);
    const res = await magicEdenApi.get(endpointPath + queryStringParams, {
        headers,
    });
    return res.data;
};
/**
 * Specifig query functions
 */
export const getListNftsByMintAddresses = async (mintAddresses) => {
    if (mintAddresses.length === 0) {
        return Promise.resolve([]);
    }
    const reqPromises = mintAddresses.map(async (mint) => {
        const res = await magicEdenApi.get(`/rpc/getNFTByMintAddress/${mint}`);
        return res.data;
    });
    const responses = await Promise.allSettled(reqPromises);
    return responses
        .filter((res) => res.status === 'fulfilled')
        .map(res => res.value.results);
};
export const useMagicEdenQuery = (queryKey, opts = {}) => {
    return useQuery({
        queryKey,
        queryFn: magicEdenApiQueryFn,
        retry: 0,
        refetchOnWindowFocus: false,
        onError: handleHttpClientError,
        ...opts,
    });
};
